import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {RouterModule} from '@angular/router'
import {MatButtonModule} from '@angular/material/button'
import {HeaderComponent} from './header/header.component'
import {MatMenuModule} from '@angular/material/menu'
import {MatIconModule} from '@angular/material/icon'
import {BankIdModule} from '@sparbanken-syd/sparbanken-syd-bankid'
import {CertDialogComponent} from './cert-dialog/cert-dialog.component'
import {HomeModule} from '../home/home.module'
import {MatDatepickerModule} from '@angular/material/datepicker'
import {MatDialogModule} from '@angular/material/dialog'
import {MatInputModule} from '@angular/material/input'
import {MatSelectModule} from '@angular/material/select'
import {ReactiveFormsModule} from '@angular/forms'


@NgModule({
  declarations: [
    HeaderComponent,
    CertDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    BankIdModule,
    HomeModule,
    MatDatepickerModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  exports: [
    HeaderComponent
  ]
})
export class SpbCommonModule {
}
