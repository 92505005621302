<div class="spb-holder">
  <spb-header></spb-header>
  <div>
    <router-outlet></router-outlet>
  </div>
  <div class="spb-filler"></div>
  <spb-footer
    [token]="(configService.accessToken$ | async)"
    [license]="true"
    [version]="version"
    (logoutUser)="configService.logout()"
    copyrightYears="2023"
  ></spb-footer>
</div>

